import React, { useState } from "react";
import { Button, Text, TextInput, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import "./landingStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IconUser, IconAt } from "@tabler/icons-react";
import { NativeSelect, rem } from "@mantine/core";
import { Modal } from "@mantine/core";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";

const Landing = () => {
  const [isOpen1, setIsOpen1] = useState(false);

  const toggleAnswer1 = () => {
    setIsOpen1(!isOpen1);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const toggleAnswer2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const toggleAnswer3 = () => {
    setIsOpen3(!isOpen3);
  };
  const [isOpen4, setIsOpen4] = useState(false);

  const toggleAnswer4 = () => {
    setIsOpen4(!isOpen4);
  };
  const [isOpen5, setIsOpen5] = useState(false);

  const toggleAnswer5 = () => {
    setIsOpen5(!isOpen5);
  };
  const [isOpen6, setIsOpen6] = useState(false);

  const toggleAnswer6 = () => {
    setIsOpen6(!isOpen6);
  };

  const userIcon = <IconUser style={{ width: rem(16), height: rem(16) }} />;
  const emailIcon = <IconAt style={{ width: rem(16), height: rem(16) }} />;
  const phoneIcon = <faPhone style={{ width: rem(16), height: rem(16) }} />;

  const navigate = useNavigate();

  const handleSalesClick = () => {
    navigate("/sales");
  };

  //-----------------------------------------Form data-------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };
  return (
    <>
      <div>
        {/* first section */}
        <div>
          <div>
            {/* Navbar */}
            <div className="pt-10">
              {" "}
              <div className="flex items-center justify-between px-4  py-2 mb-20 bg-[#191919] mx-40   rounded-3xl">
                {/* Logo */}

                <div className="ml-4">
                  <img
                    src="images/velara-logo-blue.png"
                    alt="velara-logo"
                    className="w-40 h-auto"
                  />{" "}
                </div>

                {/* Centered buttons */}
                <div className="flex justify-center mr-28">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="white"
                    className="font-josefin"
                  >
                    Home
                  </Button>

                  <Button
                    variant="transparent"
                    size="xl"
                    color="white"
                    className="font-josefin"
                    onClick={handleSalesClick} // Add the onClick handler here
                  >
                    Sales
                  </Button>
                </div>

                {/* Right corner button */}
                <div>
                  {/* <Button
                    variant="filled"
                    size="lg"
                    radius="lg"
                    style={{ color: "black" }}
                    color="#c6f64f"
                    className="font-josefin"
                  >
                    Log in
                  </Button> */}
                </div>
              </div>
            </div>
            {/* first section */}
            <div className="section1-background">
              {" "}
              <div className="flex flex-row justify-center space-x-10">
                <div className="mt-16">
                  <h1 className="mb-8 text-6xl font-bold text-center font-josefin">
                    Reach Millions <span className="text-[#0075ff]">from </span>
                    <span className="block mb-5"></span> one Video with{" "}
                    <span className="text-[#fc419c]">Velara </span>
                  </h1>
                  <div className="flex items-center justify-center ">
                    <p className="text-2xl font-josefin">
                      Generate compelling videos with AI- text-to-video
                    </p>
                  </div>

                  <div className="flex items-center justify-center mt-16 space-x-4">
                    {/* <Button
                      variant="filled"
                      size="lg"
                      radius="md"
                      style={{ color: "black" }}
                      color="#c6f64f"
                      className="font-josefin"
                    >
                      Log in
                    </Button>
                    <Button
                      variant="filled"
                      size="lg"
                      radius="md"
                      color="#8e72ff"
                      className="font-josefin"
                    >
                      Generate your first video
                    </Button> */}
                  </div>
                </div>
              </div>
              <div className="flex justify-center my-10">
                <img src="images/section-1-image1.png" />
              </div>
            </div>
          </div>
        </div>
        {/* second section */}
        <div className=" bg-[#191919] py-32 space-y-6 section2-background">
          <div className="flex justify-center ">
            <h1 className="text-3xl text-white font-josefin">
              Trusted by 4,000+ companies
            </h1>
          </div>
          <div className="flex justify-center space-x-16">
            <img
              src="images/Fictional-company-logo-1.png"
              alt="Fictional-company-logo-1"
            />
            <img
              src="images/Fictional-company-logo-2.png"
              alt="Fictional-company-logo-2"
            />
            <img
              src="images/Fictional-company-logo-3.png"
              alt="Fictional-company-logo-3"
            />
            <img
              src="images/Fictional-company-logo-4.png"
              alt="Fictional-company-logo-4"
            />
            <img
              src="images/Fictional-company-logo-5.png"
              alt="Fictional-company-logo-5"
            />
            <img
              src="images/Fictional-company-logo-6.png"
              alt="Fictional-company-logo-6"
            />
          </div>
        </div>

        {/* section three */}
        <div className="p-20 section3-background">
          <div className="flex flex-col items-center justify-center space-y-10">
            <div>
              {" "}
              <img src="images/medal-star.png" alt="medal-star" />
            </div>
            <h1 className="font-josefin text-3xl text-[#fa459c]">
              F E A T U R E S
            </h1>

            <h1 className="text-5xl font-bold text-black font-josefin">
              Personalized Outreach, Personalized Results
            </h1>
            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-black font-josefin">
                Powerful, self-serve product and growth analytics to help you
                convert, engage, and retain more users. Trusted by over 4,000
                startups.
              </h1>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center py-20 space-y-5">
            <div className="flex flex-row space-x-5">
              <div className="bg-[#6d49ff] rounded-2xl p-10 space-y-10 section3-card1-background">
                <h1 className="text-4xl font-bold text-white font-josefin">
                  Personalized <br /> Video
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-start text-xl text-white font-josefin">
                    <FontAwesomeIcon icon={faCircleCheck} className="mr-2" />
                    <div>
                      Automatically personalise every video to
                      <span className="block">
                        your customers and prospects
                      </span>
                    </div>
                  </li>
                  <li className="flex items-center text-xl text-white font-josefin">
                    <FontAwesomeIcon icon={faCircleCheck} className="mr-2" />
                    Record only once and send to many
                  </li>
                </ul>
              </div>
              <div>
                <img src="images/section-3-image1.png" alt="section-3-image1" />
              </div>
            </div>
            <div className="flex flex-row space-x-5">
              {" "}
              <div>
                <img src="images/section-3-image2.png" alt="section-3-image1" />
              </div>
              <div className="bg-[#a7e40f] rounded-2xl p-10 space-y-10 section3-card1-background">
                <h1 className="text-4xl font-bold text-black font-josefin">
                  Automate Your <br />
                  Entire Workflow
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-center text-xl text-black font-josefin">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mr-2"
                      color="black"
                    />
                    Always give customers a human to chat.{" "}
                  </li>
                  <li className="flex items-start text-xl text-black font-josefin">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="mr-2"
                      color="black"
                    />
                    <div>
                      Automate customer support and close{" "}
                      <span className="block">leads faster.</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>{" "}
            <div className="flex flex-row space-x-5">
              <div className="bg-[#fc419c] rounded-2xl p-10 space-y-10 section3-card3-background">
                <h1 className="text-4xl font-bold text-white font-josefin">
                  One-on-One <br />
                  Connection
                </h1>
                <ul className="space-y-2">
                  <li className="flex items-start text-xl text-white font-josefin">
                    <FontAwesomeIcon icon={faCircleCheck} className="mr-2" />
                    <div>
                      Engage with your customers and{" "}
                      <span className="block">
                        prospects like you know them
                      </span>
                    </div>
                  </li>
                  <li className="flex items-start text-xl text-white font-josefin">
                    <FontAwesomeIcon icon={faCircleCheck} className="mr-2" />
                    <div>
                      Humanise communication and dont{" "}
                      <span className="block">don't be a random stranger </span>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <img src="images/section-3-image3.png" alt="section-3-image1" />
              </div>
            </div>
          </div>
        </div>

        {/* 3.5 section */}
        <div className="p-20 section35-background">
          <div className="flex flex-col items-center justify-center my-32 space-y-10">
            <div>
              {" "}
              <img src="images/unlimited.svg" alt="medal-star" />
            </div>
            <h1 className="font-josefin text-3xl text-[#6d49ff]">
              F R E E - D E M O
            </h1>

            <h1 className="text-5xl font-bold text-black font-josefin">
              Try Demo Now !{" "}
            </h1>
          </div>

          <div className="flex items-center justify-center w-full">
            {" "}
            <div className="flex flex-col  mb-40 space-y-10 border-8 rounded-3xl border-[#d0c2ff] p-10 w-full max-w-6xl bg-[#f3f0ff]">
              <div className="relative flex items-center justify-center">
                <div className="absolute left-0">
                  <img src="images/three-dots.png" alt="three-dots" />
                </div>
                <div>
                  <img
                    src="images/velara-logo.png"
                    alt="velara-logo"
                    className="h-auto w-44"
                  />
                </div>
              </div>
              <div className="border-dashed border rounded-3xl  border-[#d0c2ff] w-full max-w-6xl bg-white">
                <div className="flex flex-col p-10">
                  <div className="rounded-2xl h-[320px] w-full bg-[#f3f0ff] mb-6 p-8">
                    <input
                      type="file"
                      accept="audio/*"
                      multiple
                      className="hidden"
                      id="audio-upload-voice-replication"
                    />
                    <label
                      htmlFor="audio-upload-voice-replication"
                      className="flex items-center justify-center w-full h-full border border-[#bdabf6] border-dashed cursor-pointer rounded-2xl"
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src="images/video-add.png"
                          alt="Upload Audio"
                          className="h-[50px] w-[50px]"
                        />
                        <h1 className="text-gray-500">Upload Video</h1>
                      </div>
                    </label>
                  </div>
                  <textarea
                    className="rounded-2xl h-40 w-full bg-[#f3f0ff] p-4 text-gray-500 resize-none outline-none"
                    placeholder="Enter your script here..."
                  ></textarea>

                  <div className="flex items-center justify-center w-full h-[100px] space-x-2">
                    <Button
                      size="xl"
                      radius="lg"
                      variant="filled"
                      color="#886bff"
                      onClick={open}
                    >
                      Try Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* forth section */}
        <div className="p-20 section4-background">
          <div className="flex flex-col items-center justify-center space-y-10">
            <div>
              {" "}
              <img src="images/bubble.png" alt="medal-star" />
            </div>
            <h1 className="font-josefin text-3xl text-[#6d49ff]">
              GET - STARTED
            </h1>

            <div className="flex flex-col items-center text-5xl font-bold text-white font-josefin">
              Unlock the power of AI to
              <span className="block text-center">
                personalize your outreach at scale.
              </span>
            </div>

            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-white font-josefin">
                Start your 30-day free trial today.
              </h1>
            </div>
            <div className="flex items-center justify-center mt-16 space-x-16">
              {/* <Button
                variant="filled"
                size="lg"
                radius="md"
                style={{ color: "black" }}
                color="#c6f64f"
                className="font-josefin"
              >
                Get Started
              </Button>
              <Button
                variant="filled"
                size="lg"
                radius="md"
                color="#8e72ff"
                className="font-josefin"
              >
                Learn More
              </Button> */}
            </div>
          </div>
          <div className="flex items-center justify-center py-20 ">
            <img src="images/rocket.png" alt="rocket" />
          </div>
        </div>
        {/* fifth section */}
        <div className="relative p-20 overflow-hidden ">
          <div className="absolute top-0 left-0 w-64 h-64 gradient-background"></div>
          <div className="absolute top-0 right-0 w-64 h-64 gradient-background2"></div>

          <div className="relative z-10 flex flex-col items-center justify-center space-y-10">
            <div>
              <img src="images/ranking.png" alt="medal-star" />
            </div>
            <h1 className="font-josefin text-3xl text-[#6d49ff]">
              S T A T I S T I C S
            </h1>

            <div className="flex flex-col items-center text-5xl font-bold text-black font-josefin">
              Not Just Impressive, But
              <span className="block text-center">
                Phenomenally Transformative
              </span>
            </div>

            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-black font-josefin">
                Everything you need to create. We’ve done all the heavy lifting
                so you don’t have to - the perfect starting point for any
                project.
              </h1>
            </div>
            <div className="flex items-center justify-center mt-16 space-x-8">
              <Button
                variant="filled"
                size="lg"
                radius="md"
                style={{ color: "white" }}
                color="#8e72ff"
                className="font-josefin"
                onClick={open}
              >
                Generate your first video
              </Button>
              {/* <Button
                variant="filled"
                size="lg"
                radius="md"
                color="#c6f64f"
                className="font-josefin"
                style={{ color: "black" }}
              >
                Learn More
              </Button> */}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center py-20 space-x-10">
            <img src="images/section-5-image1.png" alt="section-5-image1" />
            <img src="images/section-5-image2.png" alt="section-5-image2" />
            <img src="images/section-5-image3.png" alt="section-5-image3" />
            <img src="images/section-5-image4.png" alt="section-5-image4" />
          </div>
        </div>
        {/* sixth section */}
        <div className=" p-20 bg-[#191919] section6-background">
          <div className="relative z-10 flex flex-col items-center justify-center space-y-10">
            <div className="flex flex-col items-center justify-center space-y-6 pb-36">
              <h1 className="text-5xl font-bold text-white font-josefin ">
                Frequently asked questions
              </h1>
              <h1 className="font-josefin text-xl  text-[#cdccd1] ">
                Everything you need to know about the product and boiling.
              </h1>
            </div>
            <div className="flex flex-col items-center justify-center pb-20 space-y-8 ">
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer1}
                >
                  <h1 className="text-xl text-white font-josefin">
                    Is there a free trial available?
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen1 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen1 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer2}
                >
                  <h1 className="text-xl text-white font-josefin">
                    Can I change my plan later?{" "}
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen2 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen2 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer3}
                >
                  <h1 className="text-xl text-white font-josefin">
                    What is your cancellation policy?{" "}
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen3 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen3 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer4}
                >
                  <h1 className="text-xl text-white font-josefin">
                    Can other info be added to an invoice?{" "}
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen4 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen4 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer5}
                >
                  <h1 className="text-xl text-white font-josefin">
                    How does billing work?{" "}
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen5 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen5 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div
                  className="p-6 bg-[#343434] rounded-3xl flex justify-between items-center cursor-pointer"
                  onClick={toggleAnswer6}
                >
                  <h1 className="text-xl text-white font-josefin">
                    How do I change my account email?{" "}
                  </h1>
                  <FontAwesomeIcon
                    icon={isOpen6 ? faChevronUp : faChevronDown}
                    color="white"
                    className="ml-2"
                  />
                </div>
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                    isOpen6 ? "max-h-40" : "max-h-0"
                  }`}
                >
                  <div className="p-6 mt-2 text-lg text-white rounded-3xl font-josefin">
                    Yes, you can try us for free for 30 days. If you want, we’ll
                    provide you with a free, personalized 30-minute onboarding
                    call to get you up and running as soon as possible.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* seventh section */}
        <div className="p-20 section7-background">
          <div className="relative z-10 flex flex-col items-center justify-center space-y-10">
            <div>
              <img src="images/like-tag.png" alt="like-tag" />
            </div>
            <h1 className="font-josefin text-3xl text-[#6d49ff]">
              R E V I E W S
            </h1>

            <div className="flex flex-col items-center text-5xl font-bold text-black font-josefin">
              What People say about us?
            </div>

            <div className="flex pr-20">
              <img src="images/dp-girl-bg1.png" alt="dp-girl-bg1" />
              <img src="images/dp-girl.png" alt="dp-girl" />
              <img
                className="h-40"
                src="images/dp-girl-bg2.png"
                alt="dp-girl-bg2"
              />
            </div>

            <div>
              <img src="images/five-stars.png" alt="five-stars" />
            </div>

            <div>
              <h1 className="font-josefin text-xl text-[#666668]">
                Head of Design, Layers
              </h1>
            </div>

            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-black font-josefin">
                Everything you need to create. We’ve done all the heavy lifting
                so you don’t have to - the perfect starting point for any
                project.
              </h1>
            </div>

            <div>
              <h1 className="font-josefin text-xl text-[#666668]">
                — Mathilde Lewis
              </h1>
            </div>
          </div>
        </div>

        {/* eigth section */}
        <div className="p-40 section8-background">
          <div className="flex flex-col items-center justify-center mb-20">
            <h1 className="text-5xl font-bold text-black font-josefin">
              Contact Us
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
            <TextInput
              leftSectionPointerEvents="none"
              variant="filled"
              size="xl"
              radius="lg"
              leftSection={emailIcon}
              placeholder="Email"
              className="w-[45rem]"
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              type="email"
            />

            <Button
              variant="filled"
              size="lg"
              radius="md"
              style={{ color: "white" }}
              color="#7b5aff"
              className="font-josefin"
              onClick={handleSubmit}
            >
              Contact Us
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <TextInput
            leftSectionPointerEvents="none"
            variant="filled"
            size="xl"
            radius="lg"
            leftSection={emailIcon}
            placeholder="Email"
            className="w-[35rem]"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
          />

          <Button
            variant="filled"
            size="lg"
            radius="md"
            style={{ color: "white" }}
            color="#7b5aff"
            className="font-josefin"
            onClick={handleSubmit}
          >
            Contact Us
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Landing;
