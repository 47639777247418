import React, { useState } from "react";
import "./SalesStyles.css";
import { Button, Input } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDisclosure } from "@mantine/hooks";
import { Text, TextInput, Group } from "@mantine/core";
import { Modal } from "@mantine/core";

const testimonials = [
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  {
    starsImage: "images/review2-stars.png",
    profileImage: "images/review1.png",
    title: "Head of Design, Layers",
    quote:
      "Love the simplicity of the service and the prompt customer support. We can’t imagine working without it.",
    author: "Mathilde Lewis",
  },
  {
    starsImage: "images/review1-stars.png",
    profileImage: "images/review2.png",
    title: "Head of Marketing, Widgets",
    quote:
      "Fantastic experience from start to finish. We’ve seen great results!",
    author: "John Doe",
  },
  // Add more testimonials as needed
];

const Sales = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate("/");
  };

  //-------------------------------------------form data---------------------------------------------//

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const API_URL = process.env.REACT_APP_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email address.");
      return;
    }

    try {
      const response = await fetch(`${API_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        setFormData({ email: "" }); // Reset form data when modal is closed
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("Error occurred during form submission.");
    }
  };

  //---------------------------------------coming soon modal----------------------------------//

  const [opened, { open, close }] = useDisclosure(false);
  const handleClose = () => {
    setFormData({ email: "" }); // Reset form data when modal is closed
    close();
  };

  return (
    <>
      <div>
        {/* first section */}
        <div>
          <div className="sales-section1-background ">
            {/* Navbar */}
            <div className="pt-10">
              {" "}
              <div className="flex items-center justify-between px-4  py-2 mb-20 bg-[#191919] mx-40   rounded-3xl">
                {/* Logo */}

                <div className="ml-4">
                  <img
                    src="images/velara-logo-blue.png"
                    alt="velara-logo"
                    className="w-40 h-auto"
                  />{" "}
                </div>

                {/* Centered buttons */}
                <div className="flex justify-center mr-28">
                  <Button
                    variant="transparent"
                    size="xl"
                    color="white"
                    className="font-josefin"
                    onClick={handleHomeClick} // Add the onClick handler here
                  >
                    Home
                  </Button>

                  <Button
                    variant="transparent"
                    size="xl"
                    color="white"
                    className="font-josefin"
                  >
                    Sales
                  </Button>
                </div>

                {/* Right corner button */}
                <div>
                  {/* <Button
                    variant="filled"
                    size="lg"
                    radius="lg"
                    style={{ color: "black" }}
                    color="#c6f64f"
                    className="font-josefin"
                  >
                    Log in
                  </Button> */}
                </div>
              </div>
            </div>

            {/* first section */}
            <div className="pb-96">
              {" "}
              <div className="flex flex-row justify-center space-x-10">
                <div className="mt-16">
                  <h1 className="mb-10 text-5xl font-bold text-center font-josefin">
                    AI <span className="text-[#0075ff]">Text</span>-to-
                    <span className="text-[#fc419c]">Video</span>
                  </h1>
                  <h1 className="mb-10 font-bold text-center text-7xl font-josefin">
                    For sales teams who
                    <span className="block my-3">want to win</span>
                    <span className="block my-3">president's club</span>
                  </h1>

                  <div className="flex items-center justify-center text-center">
                    <p className="text-2xl font-josefin">
                      Book more meetings and exceed your targets with the first
                      <br /> best AI video platform for elite sellers.{" "}
                    </p>
                  </div>

                  <div className="flex items-center justify-center mt-16 space-x-4">
                    <Button
                      variant="filled"
                      size="lg"
                      radius="md"
                      color="#8e72ff"
                      className="font-josefin"
                      onClick={open}
                    >
                      Join the waitlist{" "}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* section two */}
        <div className="py-20 sales-section2-background">
          <div className="flex flex-col items-center justify-center space-y-10">
            <h1 className="text-6xl font-bold text-black font-josefin">
              Reviews{" "}
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center py-20 space-y-5">
            <div className="flex flex-row space-x-5">
              <div>
                <img
                  src="images/sales-section2.png"
                  alt="section-3-image1"
                  className="w-full h-auto"
                />
              </div>
            </div>
          </div>

          <div className="py-20 overflow-hidden">
            <div className="flex space-x-10 animate-scroll">
              {testimonials.map((testimonial, index) => (
                <div
                  className="border-2 border-[#d9d6e6] rounded-3xl flex flex-col space-y-16 py-8 px-10 w-[60vh] h-[45vh]"
                  key={index}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col space-y-10">
                      <img
                        src={testimonial.starsImage}
                        className="w-[80%] h-auto"
                        alt="stars"
                      />
                      <h1 className="text-2xl text-[#676768] font-josefin">
                        {testimonial.title}
                      </h1>
                    </div>
                    <div>
                      <img
                        src={testimonial.profileImage}
                        className="w-full h-auto"
                        alt="profile"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col space-y-10">
                    <h1 className="text-2xl text-[#414142] font-josefin">
                      {`“${testimonial.quote}”`}
                    </h1>
                    <h1 className="text-2xl text-[#676768] font-josefin">
                      — {testimonial.author}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* third section */}
        <div className="flex flex-row items-center justify-center p-20 space-x-10 overflow-hidden sales-section3-background">
          <div className="flex flex-col items-center justify-center space-y-10">
            <div className="flex flex-col space-y-10 w-[50vh]">
              <div className="flex flex-col text-6xl font-bold text-black font-josefin">
                Walk your path{" "}
                <span className="block my-3 ">
                  to <span className="text-[#6d49ff]">president's</span>{" "}
                </span>
                <span className="block my-3 text-[#6d49ff]"> club </span>
              </div>

              <div className="">
                <h1 className="max-w-3xl text-2xl text-left text-[#414142] font-josefin">
                  Cut through the noise and start landing deals by personalizing
                  outreach using generated videos trained using your voice and
                  face.
                </h1>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center py-20 space-x-10">
            <img src="images/sales-section3.png" alt="section-5-image1" />
          </div>
        </div>
        {/* forth section */}
        <div className="p-20 pt-80 sales-section4-background">
          <div className="flex flex-col items-center justify-center space-y-16">
            <div className="flex flex-col items-center text-6xl font-bold text-white font-josefin">
              Record a video of{" "}
              <span className="block text-center">yourself </span>
            </div>

            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-[#bab7c3] font-josefin">
                You could upload a video of yourself and generate multiple
                videos personalized to your prospects.{" "}
              </h1>
            </div>
            <div className="flex items-center justify-center mt-16 space-x-16">
              <Button
                variant="filled"
                size="lg"
                radius="md"
                color="#8e72ff"
                className="font-josefin"
                onClick={open}
              >
                Join the waitlist{" "}
              </Button>
            </div>
          </div>
          <div className="flex items-center justify-center py-20 ">
            <img src="images/sales-section4.png" alt="rocket" />
          </div>
        </div>

        {/* fifth section */}
        <div className="p-20 sales-section35-background">
          <div className="flex flex-col items-center justify-center my-32 space-y-10">
            <div>
              {" "}
              <img src="images/unlimited.svg" alt="medal-star" />
            </div>
            <h1 className="font-josefin text-3xl text-[#6d49ff]">
              F R E E - D E M O
            </h1>

            <h1 className="text-5xl font-bold text-black font-josefin">
              Try Demo Now !{" "}
            </h1>
          </div>

          <div className="flex items-center justify-center w-full">
            {" "}
            <div className="flex flex-col  mb-40 space-y-10 border-8 rounded-3xl border-[#d0c2ff] p-10 w-full max-w-6xl bg-[#f3f0ff]">
              <div className="relative flex items-center justify-center">
                <div className="absolute left-0">
                  <img src="images/three-dots.png" alt="three-dots" />
                </div>
                <div>
                  <img
                    src="images/V.png"
                    alt="velara-logo"
                    className="w-full h-auto"
                  />
                </div>
              </div>
              <div className="border-dashed border rounded-3xl  border-[#d0c2ff] w-full max-w-6xl bg-white">
                <div className="flex flex-col p-10">
                  <div className="rounded-2xl h-[320px] w-full bg-[#f3f0ff] mb-6 p-8">
                    <input
                      type="file"
                      accept="audio/*"
                      multiple
                      className="hidden"
                      id="audio-upload-voice-replication"
                    />
                    <label
                      htmlFor="audio-upload-voice-replication"
                      className="flex items-center justify-center w-full h-full border border-[#bdabf6] border-dashed cursor-pointer rounded-2xl"
                    >
                      <div className="flex flex-col items-center">
                        <img
                          src="images/video-add.png"
                          alt="Upload Audio"
                          className="h-[50px] w-[50px]"
                        />
                        <h1 className="text-gray-500">Upload Video</h1>
                      </div>
                    </label>
                  </div>
                  <textarea
                    className="rounded-2xl h-40 w-full bg-[#f3f0ff] p-4 text-gray-500 resize-none outline-none"
                    placeholder="Enter your script here..."
                  ></textarea>

                  <div className="flex items-center justify-center w-full h-[100px] space-x-2">
                    <Button
                      size="xl"
                      radius="lg"
                      variant="filled"
                      color="#886bff"
                      onClick={open}
                    >
                      Try Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* sixth section */}
        <div className="px-20 pt-56 pb-40 sales-section6-background">
          <div className="relative z-10 flex flex-col items-center justify-center space-y-10">
            <div className="flex flex-col items-center text-5xl font-bold text-black font-josefin">
              <h1 className="mb-8 text-6xl font-bold text-center font-josefin">
                Flywheel for{" "}
                <span className="block my-3 ">
                  ambitious <span className=" text-[#fc419c]">sales teams</span>
                </span>
              </h1>{" "}
            </div>
            <div className="flex justify-center">
              <h1 className="max-w-3xl text-2xl text-center text-black font-josefin">
                Velara is specifically built not just for sales teams but those
                who want to consistently exceed their target.
              </h1>
            </div>

            <div className="">
              <img src="images/sales-section6.png" alt="dp-girl" />
            </div>
          </div>
        </div>

        {/* seventh section */}
        <div className="flex flex-row items-center justify-center p-20 space-x-10 overflow-hidden bg-[#191919] sales-section7-background">
          <div className="flex flex-col items-center justify-center space-y-10">
            <div className="flex flex-col space-y-10 w-[50vh]">
              <div className="flex flex-col space-y-2 text-6xl font-bold text-white font-josefin">
                <span className="block">Experience how </span>{" "}
                <span className="block text-[#a7e40f]">
                  Velara <span className="text-white"> can help</span>{" "}
                </span>
                <span className="block text-white"> you exceed your </span>
                <span className="block text-[#6d49ff]"> sales target</span>{" "}
              </div>

              <div className="">
                <h1 className="max-w-3xl text-2xl text-left text-[#a7e40f] font-josefin">
                  Drive revenue growth using personalized video.
                </h1>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center py-20 space-x-10">
            <img src="images/sales-section7.png" alt="section-5-image1" />
          </div>
        </div>

        {/* eighth section */}
        <div className="relative px-20 pt-56 pb-40">
          {/* Gradient Overlay */}
          <div className="sales-gradient-background2"></div>
          <div className="relative z-10 flex flex-col items-center justify-center space-y-10">
            <div className="flex flex-col items-center text-5xl font-bold text-black font-josefin">
              <h1 className="mb-8 text-6xl font-bold text-center font-josefin">
                Put yourself in front of{" "}
                <span className="block my-3">your prospects</span>
              </h1>{" "}
            </div>
            <div className="flex items-center justify-center space-x-2">
              <Input
                variant="filled"
                size="xl"
                radius="lg"
                value={formData.email}
                onChange={(e) => handleChange("email", e.target.value)}
                type="email"
                placeholder="Enter your email"
                className="w-[60vh]"
              />
              <Button
                variant="filled"
                size="lg"
                radius="lg"
                color="#8e72ff"
                className="font-josefin"
                style={{
                  color: "white",
                }}
                onClick={handleSubmit}
              >
                Join the waitlist
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        overlayOpacity={0.5}
        overlayBlur={3}
        padding={"2rem"}
        size="auto"
      >
        <div className="pb-10 space-y-4">
          <h1 className=" text-5xl font-bold text-[#5f3dc4] text-center">
            🎉 Coming Soon!{" "}
          </h1>
          <h1 className="p-6 text-3xl font-semibold text-[#6b7280] text-center">
            Subscribe to get early access and be the
            <br /> first to know when we launch!
          </h1>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6">
          <Input
            variant="filled"
            size="xl"
            radius="lg"
            value={formData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            type="email"
            placeholder="Enter your email"
            className="w-[50vh]"
          />
          <Button
            variant="filled"
            size="lg"
            radius="lg"
            color="#8e72ff"
            className="font-josefin"
            style={{
              color: "white",
            }}
            onClick={handleSubmit}
          >
            Join the waitlist
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Sales;
