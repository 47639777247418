import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing";
import Sales from "./pages/Sales";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/sales" element={<Sales />} />
      </Routes>
    </BrowserRouter>
  );
}
//WOw THis WOrks
//WoW thiS WorkS AgainL
//Helloe
//hello
//h23dctfvygbh

export default App;
